import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { startOfMonth } from 'date-fns';
import { NgXsFormModel, normalizeToDate } from 'flex-app-shared';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IntradayDealReporting } from '../../app/shared/intraday-deal-reporting/intraday-deal-reporting';
import { IntradayDealReportingService } from '../../app/shared/intraday-deal-reporting/intraday-deal-reporting.service';
import {
  IntradayDealReportingDownloadDataCommand,
  IntradayDealReportingUpdatedEvent,
  UpdateIntradayDealReportingCommand
} from './intraday-deal-reporting.actions';

class IntradayDealReportingStateModel {
  filter = NgXsFormModel.defaults({
    selectedDate: startOfMonth(new Date())
  });

  busy = false;

  busyDownloading = false;

  data: IntradayDealReporting[] = [];
}

@State({
  name: 'intradayDealReporting',
  defaults: new IntradayDealReportingStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IntradayDealReportingState {
  static baseRoute = '/intraday/deal-reporting';

  constructor(private store: Store, private router: Router, private service: IntradayDealReportingService) {}

  @Selector([IntradayDealReportingState])
  static selectedDate(state: IntradayDealReportingStateModel): Date {
    return normalizeToDate(state.filter.model.selectedDate);
  }

  @Selector([IntradayDealReportingState])
  static busy(state: IntradayDealReportingStateModel): boolean {
    return state.busy;
  }

  @Selector([IntradayDealReportingState])
  static busyDownloading(state: IntradayDealReportingStateModel): boolean {
    return state.busyDownloading;
  }

  @Selector([IntradayDealReportingState.busy, IntradayDealReportingState.initialized])
  static canDownload(isBusy: boolean, isInitialized: boolean): boolean {
    return !isBusy && isInitialized;
  }

  @Selector([IntradayDealReportingState])
  static initialized(state: IntradayDealReportingStateModel): boolean {
    return !!state.data?.length;
  }

  @Selector([IntradayDealReportingState])
  static data(state: IntradayDealReportingStateModel): IntradayDealReporting[] {
    return state.data;
  }

  @Action(RouterNavigation)
  handleRouterNavigation({ setState, dispatch }: StateContext<IntradayDealReportingStateModel>): void {
    const isInitialized = this.store.selectSnapshot(IntradayDealReportingState.initialized);
    if (!this.router.routerState.snapshot.url.startsWith(IntradayDealReportingState.baseRoute)) {
      if (isInitialized) {
        // Reset
        setState(new IntradayDealReportingStateModel());
      }
    } else {
      if (!isInitialized) {
        // Initialize
        dispatch(new UpdateIntradayDealReportingCommand(this.store.selectSnapshot(IntradayDealReportingState.selectedDate)));
      }
    }
  }

  @Action(UpdateFormValue)
  handleFormUpdate({ dispatch, getState, setState }: StateContext<IntradayDealReportingStateModel>, event: UpdateFormValue): any {
    if (
      !event.payload.path.includes('intradayDealReporting.filter') ||
      !this.router.routerState.snapshot.url.startsWith(IntradayDealReportingState.baseRoute)
    ) {
      return;
    }

    dispatch(new UpdateIntradayDealReportingCommand(this.store.selectSnapshot(IntradayDealReportingState.selectedDate)));
  }

  @Action(UpdateIntradayDealReportingCommand)
  updateInvoiceDataHandler(
    { dispatch, patchState }: StateContext<IntradayDealReportingStateModel>,
    action: UpdateIntradayDealReportingCommand
  ): any {
    patchState({
      busy: true
    });
    return this.service.getIntradayDealReporting(action.date).pipe(
      tap({
        error: () => patchState({ busy: false })
      }),
      switchMap((result) => dispatch(new IntradayDealReportingUpdatedEvent(result)))
    );
  }

  @Action(IntradayDealReportingUpdatedEvent)
  handleDataUpdated({ patchState }: StateContext<IntradayDealReportingStateModel>, { data }: IntradayDealReportingUpdatedEvent): void {
    patchState({
      data,
      busy: false
    });
  }

  @Action(IntradayDealReportingDownloadDataCommand)
  downloadData({ patchState }: StateContext<IntradayDealReportingStateModel>): any {
    patchState({
      busyDownloading: true
    });

    return this.service.downloadExcel(this.store.selectSnapshot(IntradayDealReportingState.selectedDate)).pipe(
      tap({
        complete(): void {
          patchState({
            busyDownloading: false
          });
        },
        error(): void {
          patchState({
            busyDownloading: false
          });
        }
      })
    );
  }
}
