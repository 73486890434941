import { Capacity, TimeSlot } from 'flex-app-shared';
import { EnergyPrice } from '../../../../../flex-app-shared/src/lib/core/domain/energy-price';

export enum APFASAuctionStatus {
  CONCEPT = 'CONCEPT',
  NOT_IN_USE = 'NOT_IN_USE',
  PLANNED = 'PLANNED',
  FIRM = 'FIRM',
  GATE_OPEN = 'GATE_OPEN',
  GATE_CLOSED = 'GATE_CLOSED',
  PROVISIONAL_RESULTS = 'PROVISIONAL_RESULTS',
  DEFINITIVE_RESULTS = 'DEFINITIVE_RESULTS',
  DONE = 'DONE',
  UNDER_REVIEW = 'UNDER_REVIEW',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN'
}

export type APFASBidStatus = 'PLACED' | 'ACCEPTED' | 'REJECTED' | 'WON' | 'LOST';

export class APFASResourceId {
  resourceId: string;
  codingScheme: string;
}

export class APFASResource {
  id: string;
  resourceId: APFASResourceId;

  shortName: string;
  longName: string;
}

export class APFASAuction {
  id: string;

  product: string;
  status: any;
  description: string;
  direction: 'DOWNWARDS' | 'UPWARDS';

  biddingPeriod: TimeSlot;
  deliveryPeriod: TimeSlot;

  notificationDateTime: string;
  publicationDateTime: string;

  maximumQuantity: Capacity;

  numberOfBids: number;

  openForBidding: boolean;

  resources: APFASResource[];

  allowedBidQuantities: number[];
}

export class APFASAuctionBids {
  bidId: string;
  resourceId: APFASResourceId;
  timeSeriesId: string;

  // User entered string
  bidReference: string;

  bids: APFASAuctionBid[];

  status?: APFASBidStatus;
}

export class APFASAuctionBid {
  quantity: Capacity;
  price: EnergyPrice;
}
