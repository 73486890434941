import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfHour,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  isSameHour,
  isSameISOWeek,
  isSameMonth,
  isSameWeek,
  isSameYear,
  isValid,
  parseISO,
  startOfDay,
  startOfHour,
  startOfISOWeek,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { isDate, isNumber, isString } from 'lodash-es';

/**
 *  See FA-4944.
 *
 * Function to handle date normalization of various input types into a Date.
 * Supported input types are an ISO formatted string, number, a Date or a Moment.
 *
 * If the provided Date is invalid, return null;
 *
 * @param value Date, string, number or Moment to be normalized into a Date
 * @param defaultDate Date to be used when the result does not exist or is not valid
 */
export function normalizeToDate(value: any, defaultDate: Date = null): Date | null {
  const result = normalizeToPotentiallyInvalidDate(value);

  if (isValid(result)) {
    return result;
  }

  return defaultDate;
}

export function normalizeToPotentiallyInvalidDate(value: any): Date | null {
  if (!value || value?.toString() === 'Invalid Date') {
    return null;
  }

  if (isDate(value)) {
    return value;
  }

  let parsedValue: Date = null;
  if (isString(value)) {
    parsedValue = parseISO(value);
  }
  if (isNumber(value)) {
    parsedValue = new Date(value);
  }
  if (parsedValue && parsedValue.toString() !== 'Invalid Date') {
    return parsedValue;
  }
  if (value?.toDate) {
    return value.toDate(); // Assume it is a Moment
  }
  if (Object.prototype.toString.call(value) === '[object Date]') {
    // MockDate
    return value;
  }
  console.warn('got unprocessable value in normalizeToDate: ', value);
  return null;
}

export const TIMEZONE_AMSTERDAM = 'Europe/Amsterdam';

/**
 * Calls the given function with a converted date in the Dutch time zone.
 * Can be used like: tzAmsterdam(date, startOfDay) to get the start of the Dutch day.
 */
export function tzAmsterdam(input: any, fn: (date: Date) => Date): Date {
  const date = normalizeToDate(input);
  const inputZoned = utcToZonedTime(date, TIMEZONE_AMSTERDAM);
  const fnResultZoned = fn(inputZoned);
  return zonedTimeToUtc(fnResultZoned, TIMEZONE_AMSTERDAM);
}

export function startOfTimeUnit(input: any, timeUnit: 'hour' | 'day' | 'week' | 'isoWeek' | 'month' | 'year'): Date {
  input = normalizeToDate(input);
  switch (timeUnit) {
    case 'hour':
      return startOfHour(input);
    case 'day':
      return startOfDay(input);
    case 'isoWeek':
      return startOfISOWeek(input);
    case 'week':
      return startOfWeek(input, { weekStartsOn: 1 });
    case 'month':
      return startOfMonth(input);
    case 'year':
      return startOfYear(input);
  }
}

export function endOfTimeUnit(input: any, timeUnit: 'hour' | 'day' | 'week' | 'isoWeek' | 'month' | 'year'): Date {
  input = normalizeToDate(input);
  switch (timeUnit) {
    case 'hour':
      return endOfHour(input);
    case 'day':
      return endOfDay(input);
    case 'isoWeek':
      return endOfISOWeek(input);
    case 'week':
      return endOfWeek(input);
    case 'month':
      return endOfMonth(input);
    case 'year':
      return endOfYear(input);
  }
}

export function isSameTimeUnit(inputA: any, inputB: any, timeUnit: 'hour' | 'day' | 'week' | 'isoWeek' | 'month' | 'year'): boolean {
  inputA = normalizeToDate(inputA);
  inputB = normalizeToDate(inputB);
  switch (timeUnit) {
    case 'hour':
      return isSameHour(inputA, inputB);
    case 'day':
      return isSameDay(inputA, inputB);
    case 'isoWeek':
      return isSameISOWeek(inputA, inputB);
    case 'week':
      return isSameWeek(inputA, inputB);
    case 'month':
      return isSameMonth(inputA, inputB);
    case 'year':
      return isSameYear(inputA, inputB);
  }
}

/**
 *  Util function to use with parameterized functions
 */
export function addTimeUnit(date: any, amount: number, timeUnit: 'hour' | 'day' | 'week' | 'isoWeek' | 'month' | 'year'): Date {
  const normalizedDate = normalizeToDate(date);
  switch (timeUnit) {
    case 'hour':
      return addHours(normalizedDate, amount);
    case 'day':
      return addDays(normalizedDate, amount);
    case 'week':
    case 'isoWeek':
      return addWeeks(normalizedDate, amount);
    case 'month':
      return addMonths(normalizedDate, amount);
    case 'year':
      return addYears(normalizedDate, amount);
  }
}
