import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export const SUPPORTED_PAGE_TITLE_URIS = new InjectionToken<string[]>('Supported page URIs for page titles and menu items');
export const AVAILBLE_MENU_ITEMS = new InjectionToken<string[]>(
  'Supported page URIs menu items. Should be a subset of SUPPORTED_PAGE_TITLE_URIS'
);

@Injectable({
  providedIn: 'root'
})
export class CurrentPageService {
  private foundPageSubject = new ReplaySubject<string>(1);
  matchedPage$ = this.foundPageSubject.asObservable();

  constructor(private router: Router, @Inject(SUPPORTED_PAGE_TITLE_URIS) private supportedPages: string[]) {
    this.handleUrl(router.url);

    router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationStart) => {
      this.handleUrl(event.url);
    });
  }

  private handleUrl(url: string): void {
    this.foundPageSubject.next(this.supportedPages.find((supportedPage) => url.startsWith(supportedPage)));
  }
}
