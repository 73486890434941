import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { EntityStateModel, FetchEntity, findParameterValue } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User, UserService } from '../../../../app/shared/user/user.service';
import { DeleteUserCommand, DownloadUsersDataCommand, LoadUsersCommand, ResetUsersCommand, UsersLoadedEvent } from './users.actions';

class UsersStateModel extends EntityStateModel<User[]> {
  model: User[] = [];
  isBusyDownloading: boolean = false;
}

@State<UsersStateModel>({
  name: 'usersState',
  defaults: new UsersStateModel()
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
@Injectable({
  providedIn: 'root'
})
export class UsersState {
  @Selector([UsersState])
  public static getUsers(state: UsersStateModel): Array<User> {
    return state.model;
  }

  @Selector([UsersState])
  public static isBusy(state: UsersStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector([UsersState])
  public static isInitialized(state: UsersStateModel): boolean {
    return state.isInitialized;
  }

  @Selector([UsersState])
  public static isBusySending(state: UsersStateModel): boolean {
    return state.isBusySending;
  }

  @Selector([UsersState])
  public static isBusyDownloading(state: UsersStateModel): boolean {
    return state.isBusyDownloading;
  }

  @Selector([UsersState.isBusyDownloading, UsersState.isInitialized])
  static canDownload(busyDownloading: boolean, initialized: boolean): boolean {
    return !busyDownloading && initialized;
  }

  @Selector([UsersState])
  public static error(state: UsersStateModel): string | null {
    return state.sendError;
  }

  constructor(private activatedRoute: ActivatedRoute, private usersService: UserService, protected store: Store) {}

  @Action(ResetUsersCommand)
  resetUsers({ setState }: StateContext<UsersStateModel>): void {
    setState(new UsersStateModel());
  }

  @FetchEntity()
  @Action(LoadUsersCommand, { cancelUncompleted: true })
  initUsersData({ dispatch }: StateContext<UsersStateModel>): Observable<User[]> {
    const customerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');
    if (customerId) {
      return this.usersService.getByCustomerId(customerId).pipe(tap((users) => dispatch(new UsersLoadedEvent(users))));
    } else {
      return this.usersService.getAll().pipe(tap((users) => dispatch(new UsersLoadedEvent(users))));
    }
  }

  @Action(UsersLoadedEvent)
  setUsersData({ setState, getState }: StateContext<UsersStateModel>, { payload }: UsersLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }

  @Action(DeleteUserCommand)
  deleteUser({ dispatch }: StateContext<UsersStateModel>, event: DeleteUserCommand): Observable<any> {
    return this.usersService.deleteUser(event.payload.id).pipe(tap(() => dispatch(new LoadUsersCommand())));
  }

  @Action(DownloadUsersDataCommand)
  downloadData({ patchState }: StateContext<UsersStateModel>): any {
    patchState({
      isBusyDownloading: true
    });
    return this.usersService.downloadExcel().pipe(
      tap({
        complete: () =>
          patchState({
            isBusyDownloading: false
          }),
        error: () => {
          patchState({
            isBusyDownloading: false
          });
        }
      })
    );
  }
}
