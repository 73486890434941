<h2 mat-dialog-title>Add new availability</h2>
<mat-dialog-content class="overflow-auto">
  <form [formGroup]="form" class="flex-grid cols-2">
    <ng-container formGroupName="startDate">
      <mat-form-field class="col-1">
        <mat-label>Start date</mat-label>
        <input matInput [matDatepicker]="startDatepicker" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('startDate.date').errors?.required"> Start date is required</mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container formGroupName="endDate">
      <mat-form-field class="col-1">
        <mat-label>End date</mat-label>
        <input matInput [matDatepicker]="endDatepicker" formControlName="date" [errorStateMatcher]="endDateErrorStateMatcher" />
        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('endDate.date').errors?.required"> End date is required </mat-error>
        <mat-error *ngIf="form.errors?.toDateAfterStartDate"> End date should be after start date </mat-error>
      </mat-form-field>
    </ng-container>
  </form>

  <table mat-table [dataSource]="gridPoints">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row.id) : null"
          [checked]="selection.isSelected(row.id)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="ean">
      <th mat-header-cell *matHeaderCellDef>EAN</th>
      <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.ean }}</td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Customer</th>
      <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.customerName }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.newly-selected]="isNewlySelected(row)"
      [class.newly-deselected]="isNewlyDeselected(row)"
    ></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">
    <ph-flex-vertical-center><mat-icon svgIcon="ic-old:close"></mat-icon><span>Cancel</span></ph-flex-vertical-center>
  </button>
  <button mat-stroked-button (click)="resetGridPoints()">
    <ph-flex-vertical-center><mat-icon svgIcon="ic-old:reset"></mat-icon><span>Reset grid points</span></ph-flex-vertical-center>
  </button>
  <button
    mat-flat-button
    [disabled]="form.invalid"
    [ngSwitch]="isEdit"
    [color]="isPeriodSensible() ? 'primary' : 'warn'"
    [matTooltip]="getPeriodTooltip()"
    [matTooltipDisabled]="isPeriodSensible()"
    (click)="onSubmit()"
  >
    <ph-flex-vertical-center>
      <ng-container *ngSwitchCase="true"><mat-icon svgIcon="ic-old:check"></mat-icon><span>Ok</span></ng-container>
      <ng-container *ngSwitchCase="false"><mat-icon svgIcon="ic-old:add"></mat-icon><span>Add</span></ng-container>
    </ph-flex-vertical-center>
  </button>
</mat-dialog-actions>
