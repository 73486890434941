import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { omit, pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { formatMomentAsLocalDate } from '../../core/common/format-moment-as-local-date';
import { CalendarPeriod } from '../../core/date-time/calendar-period';
import { TimeSlot } from '../../core/date-time/time-slot';
import { normalizeToDate, TIMEZONE_AMSTERDAM } from '../../core/date-time/utils';
import { Capacity } from '../../core/domain/capacity';
import { LocalDate } from '../../core/domain/local-date';
import { Money } from '../../core/domain/money';
import { PeriodView } from '../../core/domain/period';
import { DownloadService } from '../../core/download/download.service';
import { RestClientService } from '../rest-client/rest-client.service';
import { AvailabilitySchedule, AvailabilityType, ServiceAgreement } from './service-agreement';

export class R3ServiceAgreement {
  serviceAgreementId: string;
  systemReference: number;
  period: CalendarPeriod;
  maxCapacityUpward: Capacity;
  maxCapacityDownward: Capacity;
  label: string;
}

export class AdjustAvailability {
  capacity: Capacity;
  availabilityType: AvailabilityType;

  static fromTypeAndCapacity(type: AvailabilityType | null, capacityMW?: number): AdjustAvailability | null {
    if (!type) {
      // If null is provided, return null (unchanged)
      return null;
    }

    if (type === AvailabilityType.ASSURED) {
      return {
        capacity: Capacity.MW(capacityMW || 0),
        availabilityType: AvailabilityType.ASSURED
      };
    }

    return {
      availabilityType: type,
      capacity: Capacity.MW(0)
    };
  }

  /**
   * Rules to convert a default service agreement capacity to an AdjustAvailability.
   * Mainly that voluntary and mandatory are possible, but not off.
   * Null is still handled as returning null.
   */
  static fromDefaultCapacity(capacity: Capacity | null): AdjustAvailability | null {
    if (!capacity) {
      // If null is provided, return null (unchanged)
      return null;
    }

    if (Capacity.isZero(capacity)) {
      return {
        availabilityType: AvailabilityType.VOLUNTARY,
        capacity: Capacity.MW(0)
      };
    }
    return {
      availabilityType: AvailabilityType.ASSURED,
      capacity
    };
  }

  /**
   * Return true if the provided dailyAvailabilityDetails has an assured capacity
   */
  static hasAssuredAvailability(adjustAvailability: AdjustAvailability): boolean {
    if (!adjustAvailability || !adjustAvailability.capacity) {
      return false;
    }

    return adjustAvailability.availabilityType === AvailabilityType.ASSURED && Capacity.isAboveZero(adjustAvailability.capacity);
  }

  /**
   * Return true if the provided AdjustAvailability has an availability state that is not OFF
   */
  static hasAvailability(adjustAvailability: AdjustAvailability): boolean {
    if (!adjustAvailability) {
      return false;
    }

    return adjustAvailability.availabilityType !== AvailabilityType.OFF;
  }

  /**
   * Get assured capacity if available
   */
  static getAssuredCapacityMWOrNull(adjustAvailability: AdjustAvailability): null | number {
    if (!AdjustAvailability.hasAssuredAvailability(adjustAvailability)) {
      return null;
    }
    return Capacity.asMW(adjustAvailability.capacity);
  }
}

export class AvailabilityDetails implements AdjustAvailability {
  capacity: Capacity;
  availabilityType: AvailabilityType;
  hasHourlyAvailabilities: boolean;

  static isEqual(a: AvailabilityDetails, b: AvailabilityDetails): boolean {
    return (
      a.availabilityType === b.availabilityType &&
      Capacity.asMW(a.capacity) === Capacity.asMW(b.capacity) &&
      a.hasHourlyAvailabilities === b.hasHourlyAvailabilities
    );
  }

  /**
   * Get the lowest value (to be shown in a period item for example
   */
  static getMinAvailability(availabilityDetailsList: AvailabilityDetails[]): AvailabilityDetails {
    const hasAssuredAvailability = availabilityDetailsList.some((availabilityDetails) =>
      AdjustAvailability.hasAssuredAvailability(availabilityDetails)
    );

    const hasHourlyAvailabilities = availabilityDetailsList.some((availabilityDetails) => availabilityDetails.hasHourlyAvailabilities);

    if (hasAssuredAvailability) {
      const minCapacity = Math.min(
        ...availabilityDetailsList
          .map((availabilityDetails) => AdjustAvailability.getAssuredCapacityMWOrNull(availabilityDetails))
          .filter((availabilityDetails) => availabilityDetails !== null)
      );

      return {
        availabilityType: AvailabilityType.ASSURED,
        capacity: Capacity.MW(minCapacity),
        hasHourlyAvailabilities
      };
    }

    if (availabilityDetailsList.some((availabilityDetails) => availabilityDetails.availabilityType === AvailabilityType.VOLUNTARY)) {
      return {
        availabilityType: AvailabilityType.VOLUNTARY,
        capacity: Capacity.MW(0),
        hasHourlyAvailabilities
      };
    }

    return {
      availabilityType: AvailabilityType.OFF,
      capacity: Capacity.MW(0),
      hasHourlyAvailabilities
    };
  }
}

export class R3Availability {
  serviceAgreementId: string;
  period: CalendarPeriod;
  hasHourlyAvailabilities: boolean;
  upwards: AvailabilityDetails;
  downwards: AvailabilityDetails;
}

export class R3TsoAgreement {
  period: CalendarPeriod;
  upwards: boolean;
  downwards: boolean;
}

export class R3Availabilities {
  customerId: string;
  serviceAgreements: R3ServiceAgreement[];
  availabilities: R3Availability[];
  earliestEditableDay: {
    day: string;
  };
  earliestEditableDayEditableUntil: string;
  earliestAuctionDay: {
    day: string;
  };
  tsoAgreements: R3TsoAgreement[];
}

export class R3AvailabilityFee {
  period: TimeSlot;
  fee: Money;
}

export class R3AvailabilityFees {
  downwards: R3AvailabilityFee[];
  upwards: R3AvailabilityFee[];
}

@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementService extends RestClientService<ServiceAgreement> {
  constructor(private downloadService: DownloadService) {
    super('/api/v1/service-agreements');
  }

  getByCustomerIdAndPeriod(customerId: string, period: PeriodView): Observable<ServiceAgreement[]> {
    let params = new HttpParams();
    params = params.set('customerId', customerId);
    params = params.set('startDate', LocalDate.serialize(period.startDateTime));
    params = params.set('endDate', LocalDate.serialize(period.toDateTime));
    return this.http
      .get<ServiceAgreement[]>(this.endpoint, { params })
      .pipe(tap(() => this.log(`getByCustomerIdAndPeriod customerId=${customerId} period=${period}`)));
  }

  saveAvailability(serviceAgreementId: string, availability: AvailabilitySchedule): Observable<ServiceAgreement> {
    const url = `${this.endpoint}/${serviceAgreementId}/availability-schedule`;

    availability.availabilities.forEach((anAvailability) => {
      formatMomentAsLocalDate(anAvailability, ['period.startDate', 'period.endDate']);
    });

    return this.http.put<ServiceAgreement>(url, availability).pipe(tap(() => this.log(`updated id=${serviceAgreementId} availability`)));
  }

  add(value: Partial<ServiceAgreement>): Observable<ServiceAgreement> {
    const omittedValue = omit(value, ['serviceReference']);
    formatMomentAsLocalDate(omittedValue, ['period.startDate', 'period.endDate']);

    return super.add(omittedValue as any);
  }

  update(value: Partial<ServiceAgreement>): Observable<ServiceAgreement> {
    const omittedValue = omit(value, ['serviceReference']);
    formatMomentAsLocalDate(omittedValue, ['period.startDate', 'period.endDate']);

    return super.update(omittedValue as any);
  }

  downloadGridPointOverview(): Observable<HttpResponse<Blob>> {
    return this.downloadService.downloadExcel(this.endpoint);
  }

  getDailyR3Availabilities(customerId: string): Observable<R3Availabilities> {
    return this.http.get<R3Availabilities>(`${this.endpoint}/daily-r3-availabilities`, {
      params: {
        customerId
      }
    });
  }

  saveDailyR3Availability(
    customerId: string,
    serviceAgreementId: string,
    period: CalendarPeriod,
    upwards: AdjustAvailability,
    downwards: AdjustAvailability
  ): Observable<R3Availabilities> {
    return this.http.put<R3Availabilities>(
      `${this.endpoint}/daily-r3-availabilities`,
      pickBy(
        {
          customerId,
          serviceAgreementId,
          period,
          upwards,
          downwards
        },
        (value) => value !== null
      )
    );
  }

  getDailyR3AvailabilityFees(serviceAgreementId: string): Observable<R3AvailabilityFees> {
    return this.http.get<R3AvailabilityFees>(`${this.endpoint}/${serviceAgreementId}/daily-r3-availability-fee`).pipe(
      map((result) => {
        if (!result) {
          return result;
        }

        return {
          upwards: result.upwards.map((entry) => ({
            fee: entry.fee,
            period: {
              startDateTime: format(utcToZonedTime(normalizeToDate(entry.period.startDateTime), TIMEZONE_AMSTERDAM), 'yyyy-MM-dd'),
              toDateTime: format(utcToZonedTime(normalizeToDate(entry.period.toDateTime), TIMEZONE_AMSTERDAM), 'yyyy-MM-dd')
            }
          })),
          downwards: result.downwards.map((entry) => ({
            fee: entry.fee,
            period: {
              startDateTime: format(utcToZonedTime(normalizeToDate(entry.period.startDateTime), TIMEZONE_AMSTERDAM), 'yyyy-MM-dd'),
              toDateTime: format(utcToZonedTime(normalizeToDate(entry.period.toDateTime), TIMEZONE_AMSTERDAM), 'yyyy-MM-dd')
            }
          }))
        };
      })
    );
  }

  // getActivations(serviceAgreementId: string): Observable<R3Activation[]> {
  //   return this.http.get<R3Activation[]>(`${this.endpoint}/${serviceAgreementId}/activations`);
  // }
}
