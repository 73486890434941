import { Store } from '@ngxs/store';
import { TsoAgreementsState } from './tso-agreements-state.service';
import { DeleteTsoAgreementCommand, DownloadTsoAgreementsCommand, LoadTsoAgreementsCommand } from './tso-agreements.actions';
import { Injectable } from '@angular/core';
import { TsoAgreement } from '../../app/shared/tso-agreement/tso-agreement.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TsoAgreementsFacade {
  constructor(private store: Store) {}

  isBusyDownloading$ = this.store.select(TsoAgreementsState.isBusyDownloading);
  isBusyDeleting$ = this.store.select(TsoAgreementsState.isBusyDeleting);
  tsoAgreements$ = this.store.select(TsoAgreementsState.getTsoAgreements);

  init(): void {
    this.loadAll();
  }

  loadAll(): Observable<ReadonlyArray<TsoAgreement>> {
    return this.store.dispatch(new LoadTsoAgreementsCommand());
  }

  downloadAll(): void {
    this.store.dispatch(new DownloadTsoAgreementsCommand());
  }

  delete(agreement: TsoAgreement): Observable<any> {
    return this.store.dispatch(new DeleteTsoAgreementCommand(agreement));
  }
}
