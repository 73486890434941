import { Action, createSelector, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AdjustmentDirection, AsyncMessageReceivedEvent, MessageType, NgXsFormModel } from 'flex-app-shared';
import { getDaysInMonth, isDate, startOfMonth } from 'date-fns';
import {
  R3InvoiceDataRecalculateCommand,
  R3InvoiceDataRecalculateForServiceAgreementCommand,
  R3InvoiceDataUpdatedEvent,
  R3InvoiceDownloadDataCommand,
  UpdateR3InvoiceDataCommand
} from './r3-invoice-data.actions';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Router } from '@angular/router';
import { IncidentReserveInvoiceData } from '../../app/shared/r3-invoice-data/r3-invoice-data';
import { R3InvoiceDataService } from '../../app/shared/r3-invoice-data/r3-invoice-data.service';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RouterNavigation } from '@ngxs/router-plugin';

class R3InvoiceDataStateModel {
  filter = NgXsFormModel.defaults({
    selectedDate: startOfMonth(new Date()),
    direction: AdjustmentDirection.UPWARDS
  });

  baseDisplayedColumns: string[] = ['customerName', 'customerLegalName', 'customerBillingId', 'serviceAgreementSystemReference'];

  busy = false;

  busyDownloading = false;
  busyRecalculating = false;
  busyRecalculatingServiceAgreementId: string;

  data: IncidentReserveInvoiceData[] = [];
}

const ALL_SERVICE_AGREEMENTS = 'all';

@State({
  name: 'r3InvoiceData',
  defaults: new R3InvoiceDataStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class R3InvoiceDataState {
  static baseRoute = '/r3-invoice-data';

  constructor(private store: Store, private router: Router, private service: R3InvoiceDataService) {}

  @Selector([R3InvoiceDataState])
  static selectedDate(state: R3InvoiceDataStateModel): Date {
    const selectedDate = state.filter.model.selectedDate;

    if (!isDate(selectedDate)) {
      // The mat datepicker returns a moment, since DateFnsAdapter is not available yet, and native data adapter doesn't do the custom month formatting.
      // @ts-ignore
      return selectedDate.toDate();
    }

    return selectedDate;
  }

  @Selector([R3InvoiceDataState])
  static busy(state: R3InvoiceDataStateModel): boolean {
    return state.busy;
  }

  @Selector([R3InvoiceDataState])
  static busyDownloading(state: R3InvoiceDataStateModel): boolean {
    return state.busyDownloading;
  }

  @Selector([R3InvoiceDataState])
  static busyRecalculating(state: R3InvoiceDataStateModel): boolean {
    return state.busyRecalculating;
  }

  @Selector([R3InvoiceDataState])
  static busyRecalculatingAll(state: R3InvoiceDataStateModel): boolean {
    return state.busyRecalculatingServiceAgreementId === ALL_SERVICE_AGREEMENTS;
  }

  @Selector([R3InvoiceDataState])
  static busyRecalculatingServiceAgreementId(state: R3InvoiceDataStateModel): string {
    return state.busyRecalculatingServiceAgreementId;
  }

  @Selector([R3InvoiceDataState.busy, R3InvoiceDataState.busyRecalculating, R3InvoiceDataState.initialized])
  static canDownload(isBusy: boolean, busyRecalculating: boolean, isInitialized: boolean): boolean {
    return !isBusy && !busyRecalculating && isInitialized;
  }

  @Selector([R3InvoiceDataState.busy, R3InvoiceDataState.busyRecalculating])
  static canRecalculate(isBusy: boolean, busyRecalculating: boolean, isInitialized: boolean): boolean {
    return !isBusy && !busyRecalculating;
  }

  @Selector([R3InvoiceDataState])
  static baseDisplayedColumns(state: R3InvoiceDataStateModel): string[] {
    return state.baseDisplayedColumns;
  }

  @Selector([R3InvoiceDataState.selectedDate])
  static dateColumns(selectedDate: Date): string[] {
    return selectedDate ? Array.from({ length: getDaysInMonth(selectedDate) }).map((_, i) => `${i + 1}`) : [];
  }

  @Selector([R3InvoiceDataState.baseDisplayedColumns, R3InvoiceDataState.dateColumns])
  static displayedColumns(baseDisplayedColumns: string[], dateColumns: string[]): string[] {
    return [...baseDisplayedColumns, ...(dateColumns ?? []), 'recalculate'];
  }

  @Selector([R3InvoiceDataState])
  static direction(state: R3InvoiceDataStateModel): AdjustmentDirection {
    return state.filter.model.direction;
  }

  @Selector([R3InvoiceDataState])
  static initialized(state: R3InvoiceDataStateModel): boolean {
    return !!state.data?.length;
  }

  @Selector([R3InvoiceDataState])
  static data(state: R3InvoiceDataStateModel): IncidentReserveInvoiceData[] {
    return state.data;
  }

  static busyRecalculatingFor(serviceAgreementId: string): any {
    return createSelector([R3InvoiceDataState.busyRecalculatingServiceAgreementId], (currentBusyServiceAgreementId) => {
      return currentBusyServiceAgreementId === serviceAgreementId;
    });
  }

  @Action(RouterNavigation)
  handleRouterNavigation({ setState, dispatch }: StateContext<R3InvoiceDataStateModel>): void {
    const isInitialized = this.store.selectSnapshot(R3InvoiceDataState.initialized);
    if (!this.router.routerState.snapshot.url.startsWith(R3InvoiceDataState.baseRoute)) {
      if (isInitialized) {
        // Reset
        setState(new R3InvoiceDataStateModel());
      }
    } else {
      if (!isInitialized) {
        // Initialize
        dispatch(
          new UpdateR3InvoiceDataCommand(
            this.store.selectSnapshot(R3InvoiceDataState.selectedDate),
            this.store.selectSnapshot(R3InvoiceDataState.direction)
          )
        );
      }
    }
  }

  @Action(UpdateFormValue)
  handleFormUpdate({ dispatch, getState, setState }: StateContext<R3InvoiceDataStateModel>, event: UpdateFormValue): any {
    if (
      !event.payload.path.includes('r3InvoiceData.filter') ||
      !this.router.routerState.snapshot.url.startsWith(R3InvoiceDataState.baseRoute)
    ) {
      return;
    }

    dispatch(
      new UpdateR3InvoiceDataCommand(
        this.store.selectSnapshot(R3InvoiceDataState.selectedDate),
        this.store.selectSnapshot(R3InvoiceDataState.direction)
      )
    );
  }

  @Action(UpdateR3InvoiceDataCommand)
  updateInvoiceDataHandler({ dispatch, patchState }: StateContext<R3InvoiceDataStateModel>, action: UpdateR3InvoiceDataCommand): any {
    patchState({
      busy: true
    });
    return this.service.getInvoiceData(action.date, action.direction).pipe(
      tap({
        error: () => patchState({ busy: false })
      }),
      switchMap((result) => dispatch(new R3InvoiceDataUpdatedEvent(result)))
    );
  }

  @Action(R3InvoiceDataUpdatedEvent)
  handleDataUpdated({ patchState }: StateContext<R3InvoiceDataStateModel>, { data }: R3InvoiceDataUpdatedEvent): void {
    patchState({
      data,
      busy: false
    });
  }

  @Action(AsyncMessageReceivedEvent)
  handleAsyncMessageReceived({ dispatch, patchState }: StateContext<R3InvoiceDataStateModel>, { message }: AsyncMessageReceivedEvent): any {
    console.warn('sse message', message.type, MessageType.IncidentReserveInvoiceDataCalculatedSseEvent);
    if (message.type === MessageType.IncidentReserveInvoiceDataCalculatedSseEvent) {
      patchState({
        busyRecalculating: false,
        busyRecalculatingServiceAgreementId: undefined
      });

      // Currently this SSE Event will only be thrown for tomorrow.
      // When the button is changed to a month recalculate we should check if the month matches the displayed month.
      const currentDirection = this.store.selectSnapshot(R3InvoiceDataState.direction);
      if (currentDirection === JSON.parse(message.payload)?.direction) {
        patchState({
          busy: true
        });

        dispatch(
          new UpdateR3InvoiceDataCommand(
            this.store.selectSnapshot(R3InvoiceDataState.selectedDate),
            this.store.selectSnapshot(R3InvoiceDataState.direction)
          )
        );
      }
    }
  }

  @Action(R3InvoiceDataRecalculateCommand)
  recalculate({ patchState }: StateContext<R3InvoiceDataStateModel>, { dialogRef }: R3InvoiceDataRecalculateCommand): any {
    patchState({
      busyRecalculating: true,
      busyRecalculatingServiceAgreementId: ALL_SERVICE_AGREEMENTS
    });

    return this.service
      .recalculate(this.store.selectSnapshot(R3InvoiceDataState.selectedDate), this.store.selectSnapshot(R3InvoiceDataState.direction))
      .pipe(
        tap({
          error: () => patchState({ busyRecalculating: false }),
          next: () => dialogRef.close()
        })
      );
  }

  @Action(R3InvoiceDataRecalculateForServiceAgreementCommand)
  recalculateForServiceAgreement(
    { patchState }: StateContext<R3InvoiceDataStateModel>,
    action: R3InvoiceDataRecalculateForServiceAgreementCommand
  ): any {
    patchState({
      busyRecalculating: true,
      busyRecalculatingServiceAgreementId: action.serviceAgreementId
    });

    return this.service
      .recalculateForServiceAgreement(
        this.store.selectSnapshot(R3InvoiceDataState.selectedDate),
        this.store.selectSnapshot(R3InvoiceDataState.direction),
        action.serviceAgreementId
      )
      .pipe(
        tap({
          error: () => patchState({ busyRecalculating: false, busyRecalculatingServiceAgreementId: undefined }),
          next: () => action.dialogRef.close()
        })
      );
  }

  @Action(R3InvoiceDownloadDataCommand)
  downloadData({ patchState }: StateContext<R3InvoiceDataStateModel>): any {
    patchState({
      busyDownloading: true
    });

    return this.service
      .downloadExcel(this.store.selectSnapshot(R3InvoiceDataState.selectedDate), this.store.selectSnapshot(R3InvoiceDataState.direction))
      .pipe(
        tap({
          complete(): void {
            patchState({
              busyDownloading: false
            });
          }
        })
      );
  }
}
